/* eslint-disable react/prop-types */
import { CheckCircleOutlined, PlusSquareOutlined, PoundCircleOutlined } from "@ant-design/icons";
import { Switch, Tooltip } from "antd";
import { SITE_NAME } from "../config";
import { fundingFeatures } from "../constants/features";

interface FeatureDetail {
  name: string;
  description: string;
}

interface FeatureDetails {
  [key: string]: FeatureDetail;
}

const featureDetails: FeatureDetails = {
  search: {
    name: "Search",
    description: "Search for people, tags, and events",
  },
  insights: {
    name: "Reporting",
    description: "Automatically create reports from your data",
  },
  calendar: {
    name: "Calendar",
    description: "Create a timetable of sessions, take registers and bookings",
  },
  people: {
    name: "People",
    description: "Manage your members and volunteers",
  },
  tags: {
    name: "Tags",
    description: "Create tags to organise your data",
  },
  coronavirus: {
    name: "Volunteer Requests",
    description: "Create volunteer requests and manage volunteers",
  },
  partners: {
    name: "Partners",
    description: "Manage your partners and their data",
  },
  surveys: {
    name: "Surveys/Outcomes",
    description: "Create surveys and collect responses",
  },
  payments: {
    name: "Payments",
    description: "Track and reconcile payments from your members",
  },
  funding: {
    name: "Funding",
    description:
      "View your previous grant applications and responses to funders",
  },
  checkInCustomers: {
    name: "Check-in members",
    description: "Check-in members at the front door",
  },
  foodChoices: {
    name: "Food stock",
    description: "Manage food stock",
  },
  grantWriter: {
    name: "Grant Writer",
    description: "Automatically generate grant applications",
  },
  documents: {
    name: "Documents",
    description: "Store documents and ask questions about them",
  },
  resources: {
    name: "Room Bookings",
    description: "Create Rooms, attach them to events, and take bookings for them through Plinth",
  }
};

const extraFeatures = [
  "resources",
  "coronavirus",
  "partners",
  "surveys",
  "payments",
  "funding",
  "checkInCustomers",
  "foodChoices",
];

export const defaultFeatures = ["insights", "calendar", "people", "tags"];
interface OrganisationFeatureSettingsProps {
  features: string[];
  handleFeatureChange: (params: { name: string; checked: boolean }) => void;
  featureDetails: FeatureDetails;
}

const OrganisationFeatureSettings: React.FC<OrganisationFeatureSettingsProps> = ({ 
  features, 
  handleFeatureChange, 
}) => (
  <>
    <h2 className="font-medium text-lg mt-0 mb-2 flex items-center space-x-4 justify-between">
      <div>
        <PoundCircleOutlined className="mr-2" /> Funding Features
        </div>
      </h2>
      <p className="mt-1 mb-4 text-gray-700">
        These features are helpful for managing your funding applications and
        reporting.
      </p>
      <div className="w-full mb-8">
        {fundingFeatures.map(({ id: feature }) => (
          <div
            key={feature}
            className="flex items-center border-b border-gray-200 py-2"
          >
            <Switch
              onChange={(checked) =>
                handleFeatureChange({ name: feature, checked })
              }
              checked={features.includes(feature)}
              className="mr-4"
              id={`switch-${feature}`}
              aria-label={featureDetails[feature].name}
              aria-checked={features.includes(feature)}
              // role="switch"
              // this prevents from building
            />
            <label
              htmlFor={`switch-${feature}`}
              className="flex-1 cursor-pointer"
            >
              <p className="my-0 font-medium">{featureDetails[feature].name}</p>
              <p className="text-gray-500 text-sm my-0">
                {featureDetails[feature].description}
              </p>
            </label>
          </div>
        ))}
      </div>

      <h2 className="font-medium text-lg mt-0 mb-0 flex items-center">
        <CheckCircleOutlined className="mr-2" /> Default Features
      </h2>
      <p className="mt-1 mb-4 text-gray-700">
        These features are enabled by default for all accounts. They&apos;re
        the best option for getting started with {SITE_NAME}.
      </p>
      <div className="w-full">
        {defaultFeatures.map((feature) => (
          <div
            key={feature}
            className="flex items-center border-b border-gray-200 py-2"
          >
            <Switch
              onChange={(checked) =>
                handleFeatureChange({ name: feature, checked })
              }
              checked={features.includes(feature)}
              disabled={features.includes(feature)}
              className="mr-4"
            />
            <div>
              <p className="my-0 font-medium">{featureDetails[feature].name}</p>
              <p className="text-gray-500 text-sm my-0">
                {featureDetails[feature].description}
              </p>
            </div>
          </div>
        ))}

        <h2 className="font-medium text-lg mt-8 mb-0 flex items-center">
          <PlusSquareOutlined className="mr-2" /> Advanced Features{" "}
          <Tooltip title="These are free to use, but are not as polished as the default features.">
            <div className="rounded-md bg-blue-100 text-blue-800 px-2 ml-2 text-xs">
              BETA
            </div>
          </Tooltip>
        </h2>
        <p className="mt-1 mb-4 text-gray-700">
          Activate more features for your organisation&apos;s account to
          unlock more functionality.
        </p>
        {extraFeatures.map((feature) => (
          <div
            key={feature}
            className="flex items-center border-b border-gray-200 py-2"
          >
            <Switch
              onChange={(checked) =>
                handleFeatureChange({ name: feature, checked })
              }
              checked={features.includes(feature)}
              className="mr-4"
            />
            <div>
              <p className="my-0 font-medium">{featureDetails[feature].name}</p>
              <p className="text-gray-500 text-sm my-0">
                {featureDetails[feature].description}
              </p>
            </div>
          </div>
        ))}
    </div>
  </>
);

export default OrganisationFeatureSettings;