import React, { useEffect, useContext } from "react";
import Router from "next/router";
import Head from "next/head";
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";

import { routeToFunderDashboard } from "../methods/account";
import OrgContext from "../components/orgContext";
import { SITE_NAME } from "../config";
import BaseAuth from "../components/auth/BaseAuth";

const Index = () => {
  const { organisation } = useContext(OrgContext);

  useEffect(() => {
    const subscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user && organisation?._id) {
        // Check if user is a form reviewer
        const assignedForms = organisation?.formReviewers?.[user.uid]?.assignedForms;

        if (assignedForms?.length) {
          // Redirect to the last assigned form and prevent other redirects
          const lastFormId = assignedForms[assignedForms.length - 1];
          setTimeout(() => {
            Router.push(`/form-responses/${lastFormId}/review`);
          }, 500); // 1 second delay
          return; // Early return to prevent other redirects
        } else if (organisation?.formReviewers?.[user.uid]) {
          // If they're a form reviewer but don't have assigned forms, go to form-responses
          Router.push("/form-responses");
          return; // Early return to prevent other redirects
        }

        // Only execute normal routing if not a form reviewer
        Router.push("/dashboard");
        routeToFunderDashboard();
      }
    });
    return () => subscribe();
  }, [organisation]);

  return (
    <div className="flex items-center justify-center w-full p-5 min-h-screen absolute top-0 z-0 text-center bg-gray-100">
      <Head>
        <title>
          {`Log in to ${SITE_NAME}`}
        </title>
      </Head>
      <div className="login-section max-w-sm w-full bg-white p-5 text-left z-10 rounded-xl shadow-xl min-w-[300px]">
        <BaseAuth showLogo defaultMode="login" signUpRedirect={`/onboarding`} />
      </div>
      <style jsx>
        {`
          .input-header {
            font-weight: 700;
            margin: 15px 0px 5px 0px;
          }
        `}
      </style>
    </div>
  );
};

export default Index;
