const emailLooksValid = (email) => {
  if (!email) return false;

  let clean;
  if (Array.isArray(email)) {
    clean = email[0]?.toLowerCase()?.trim();
  } else {
    clean = email?.toLowerCase()?.trim();
  }

  const badDomains = [
    "@example.",
    "@email.",
    "@test.",
    "localhost",
    "@invalid.",
  ];
  for (let i = 0; i < badDomains.length; i += 1) {
    const domain = badDomains[i];
    if (clean?.includes(domain)) {
      return false;
    }
  }

  return clean?.includes("@");
};

export default emailLooksValid;
