export const SAML_CAMDEN = {
  organisationName: "Camden Council",
  providerId: "saml.camden",
  logo: "./camden-council.svg",
};

export const ALL_SAML_PROVIDERS = [
  SAML_CAMDEN,
];

export default ALL_SAML_PROVIDERS;
