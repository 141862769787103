import {
  MEMBERS_COLLECTION,
  VOLUNTEERS_COLLECTION,
  ORG_DATA_COLLECTION,
} from "@/constants/collections";

export const FOR_MEMBERS = "Members";

export const FOR_VOLUNTEERS = "Volunteers";

export const FOR_PARTNERS = "Partners";

export const APPLIESTOOPTIONS = [FOR_MEMBERS, FOR_VOLUNTEERS, FOR_PARTNERS];

// Fields may have an appliesTo arr. eg. appliesTo: ["Members"]
// Forms have a formFor str eg. formFor: "Members", which then shows the appropriate fields

// These keys should match APPLIESTOOPTIONS
export const FORMSFORCOLL = {
  [FOR_MEMBERS]: {
    collection: MEMBERS_COLLECTION,
    singular: "Person",
    route: "member",
  },
  [FOR_VOLUNTEERS]: {
    collection: VOLUNTEERS_COLLECTION,
    singular: "Volunteer",
    route: "volunteer",
  },
  [FOR_PARTNERS]: {
    collection: ORG_DATA_COLLECTION,
    singular: "Partner",
    route: "partner",
  },
};

export const INTERNAL_MEMBER_FIELDS = [
  "User",
  "_id",
  "schemaForm",
  "helpPageId",
  "created",
  "creator",
  "lastLoggedIn",
  "updated",
  "formIds",
  "lastInteraction",
  "lastInteractionId",
  "managedBy",
  "locationDetails",
  "geoData",
  "geopoint",
  "exists",
  "userCreated",
  "geocodes",
];
