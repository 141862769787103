import { CheckOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import { SITE_URL } from "../config";
import useMounted from "../hooks/useMounted";

type Consent = {
  activate: () => void;
};

type CookieConsentProps = {
  consents: Consent[];
};

const getExistingConsent = (): boolean | null => {
  try {
    return (
      JSON.parse(localStorage.getItem("cookieConsent") || "null") ||
      JSON.parse(
        localStorage.getItem(
          "metomic-consented-pol:bfe99801-3ec6-4890-9116-25f067c01340",
        ) || "null",
      )?.true ||
      null
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

const CookieConsent: React.FC<CookieConsentProps> = ({ consents }) => {
  const [existingConsent, setExistingConsent] = useState<boolean | null>(null);
  const mounted = useMounted();
  const [visible, setVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mounted) {
      try {
        if (localStorage) {
          const newExistingConsent = getExistingConsent();
          setExistingConsent(newExistingConsent);
          if (newExistingConsent === null) {
            const timeout = setTimeout(() => {
              setVisible(true);
            }, 1000);
            return () => clearTimeout(timeout);
          }
        }
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    }
  }, [mounted]);

  useEffect(() => {
    if (visible && bannerRef.current) {
      bannerRef.current.focus();
    }
  }, [visible]);

  const handleReject = () => {
    try {
      if (mounted && localStorage) {
        localStorage.setItem("cookieConsent", JSON.stringify(false));
        setVisible(false);
      }
    } catch (error) {
      console.error(error);
      setVisible(false);
    }
  };

  useEffect(() => {
    if (existingConsent) {
      consents.forEach((consent) => {
        consent.activate();
      });
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [existingConsent, consents]);

  const handleAccept = () => {
    if (mounted) {
      try {
        localStorage.setItem("cookieConsent", JSON.stringify(true));
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
      setExistingConsent(true);
    }
  };

  const generateClassName = (): string => {
    if (visible) {
      return "md:mb-8 opacity-100";
    }
    if (existingConsent) {
      return "mb-m100vw opacity-0";
    }
    return "-mb-m100vw invisible opacity-0";
  };

  if (!consents?.length) {
    return null;
  }

  return (
    <div
      ref={bannerRef}
      role="dialog"
      aria-labelledby="cookie-title"
      aria-describedby="cookie-description"
      tabIndex={-1}
      style={{ zIndex: 2147483004 }}
      className={`${generateClassName()} transition-cookies duration-700 md:mr-8 bottom-0 right-0 fixed`}
    >
      <div className="md:max-w-sm w-full shadow-lg">
        <div className="border rounded-lg border-gray-200 overflow-hidden bg-white bg-opacity-100 shadow-2xl p-6">
          <h3 id="cookie-title" className="text-xl font-medium">
            Can we store cookies?
          </h3>
          <p id="cookie-description" className="opacity-80 text-sm my-4">
            We&apos;ll use them for our own statistics and to get in touch with
            you for support. As in our{" "}
            <a
              className="underline"
              href={`https://${SITE_URL}/cookies-policy`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Cookie Policy
            </a>
            . Ironically, we&apos;ll store your choice as a cookie.
          </p>
          <div className="flex justify-end">
            {!existingConsent && (
              <button
                type="button"
                onClick={handleReject}
                aria-label="Reject cookies"
                className="px-4 py-2 mr-4 hover:bg-gray-200 hover:bg-opacity-70 opacity-70 rounded-full"
              >
                No, thanks
              </button>
            )}

            <button
              type="button"
              onClick={handleAccept}
              aria-label={existingConsent ? "Cookies accepted" : "Accept cookies"}
              className="text-primary bg-accent transition-all font-semibold px-4 py-2 hover:bg-opacity-80 rounded-full"
            >
              {existingConsent ? (
                <CheckOutlined className="h-6" aria-hidden="true" />
              ) : (
                "OK, whatever"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
