import React from "react";
import classNames from "classnames";
import { ArrowLeftOutlined } from "@ant-design/icons";

export type BackButtonProps = {
  readonly className?: string;
  readonly label?: string;
  readonly onClick: () => void;
};

function BackButton({ label = "Back", onClick, className }: BackButtonProps) {
  return (
    <button
      type="button"
      className={classNames(
        "items-center flex no-print space-x-2 h-6 px-3 text-sm text-gray-800 border border-primary rounded-full transition-colors duration-200 cursor-pointer hover:bg-primary hover:text-white",
        className,
      )}
      onClick={onClick}
    >
      <ArrowLeftOutlined className="h-4 text-xs" />
      <span>{label}</span>
    </button>
  );
}

export default BackButton;
